import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import PrimaryBox from 'components/Motion_Detection/Alarm_Server/PrimaryBox';
import ForumBox from 'components/Motion_Detection/Alarm_Server/ForumBox';
import NavButtons from 'components/Motion_Detection/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Alarm Server Function",
  "path": "/Motion_Detection/Alarm_Server/",
  "dateChanged": "2017-11-27",
  "author": "Mike Polinowski",
  "excerpt": "You can use the Alarm Server function to send a http request to a connected server",
  "image": "./MD_SearchThumb_AlarmServer.png",
  "social": "/images/Search/MD_SearchThumb_AlarmServer.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_MD-Alarm-Server_white.webp",
  "chapter": "Motion Detection"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='Alarm Server Function' dateChanged='2017-11-27' author='Mike Polinowski' tag='INSTAR IP Camera' description='You can use the Alarm Server function to send a http request to a connected server' image='/images/Search/MD_SearchThumb_AlarmServer.png' twitter='/images/Search/MD_SearchThumb_AlarmServer.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Bewegungserkennung/Alarm_Server/' locationFR='/fr/Motion_Detection/Alarm_Server/' crumbLabel="Alarm Server" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "add-an-alarm-server",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#add-an-alarm-server",
        "aria-label": "add an alarm server permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add an Alarm Server`}</h2>
    <p>{`You can use the Alarm Server function to send a http request to a connected server. The server needs a compatible software to recognize this request as an alarm event and trigger further actions. This function is often supported by home alarm systems. You can add custom commands to the request to trigger specific actions in the receiving server software.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "609px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/989c3ef9e0f5af4f95b012531ef40309/d0d8c/Alarmserver_1080p_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "107.82608695652173%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAIAAABPIytRAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACq0lEQVQ4y41SWU8TURidXwlPvBnjizzi+iAPlpaqEdpKAomJRB4wxmBijEQj7bgFTQoF2kDsNkOhkraz3bn7ZqYttIgsZ04mucmce8585zNuvimOLW2MLW2OLW2OLuZHFvOj53NkMX/91da15cL4270bD54YcbMRy1qxrB3LWndXq3cu5O3VaiJnT63Vp740xx+mjN8d/NP2Ng78zYOgcAi2DsFWM+J2ExSaoHB8LBz2mW8E65brC53JpI3lnc6tD9W4aT/+2kjk7NhaPWnaj8z9ZM6KZ63pnJXIWUnTnjbtRM6O56zJT7WJ9+VaIBfmMgbnHMEQI0Qw5pzryyClxAhqrWdnUwZCqN3uuK7b7nQIYVprpbTSmnGOuqCUCSmF6JNzEYaROJVKRc4hCDHGCCEp5YkDQshzvQAEGBPG+ICcU0r7YoRQJ/Ik/8Tr2SqlIrvTYIz1xYyxIAh6lw0DY+y6ruN0EEKcczYEjPHAOQgC13UBAMNiQkg3EVVKCSE451JKQjAXfOCMEQYAeJ4XhuEpZ4Qcx4EQCiEYo5RSzgUhhHUPfTEhUTzfDzzXHa5KCBHNWYjot6NHqi6EEKBrE4mF7N8HIeRCXN6zisIPqgp8HwCglNJXQ/Eo9IWey6QN3K2EMTZcstaaMooQlNF6nAIX4o8PldaZdDqqijKqpDrbs+M43TmdARtaklarBQCglPbMe/kRhI1GA8L/mA+mHa3nMXrT7okxxu12GyLMuKCM0+5ackI4pQQe7/bJ11dHL2BfLM5sr5BSKxVglvm+P79+8Oz7/otCy3r+sn5v0k4+LU/cF5Vaen7+ImcmlWnDbw1k2nD9iIU/foGVd/DjZ+/1ivaDuYUFo1gsbp+DnZ3tyl6pvFus7EbvUqVcqtdK1UqxVt0rl2dnZv4CdSCnruVIXeoAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/989c3ef9e0f5af4f95b012531ef40309/e4706/Alarmserver_1080p_01.avif 230w", "/en/static/989c3ef9e0f5af4f95b012531ef40309/d1af7/Alarmserver_1080p_01.avif 460w", "/en/static/989c3ef9e0f5af4f95b012531ef40309/daeea/Alarmserver_1080p_01.avif 609w"],
              "sizes": "(max-width: 609px) 100vw, 609px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/989c3ef9e0f5af4f95b012531ef40309/a0b58/Alarmserver_1080p_01.webp 230w", "/en/static/989c3ef9e0f5af4f95b012531ef40309/bc10c/Alarmserver_1080p_01.webp 460w", "/en/static/989c3ef9e0f5af4f95b012531ef40309/117a4/Alarmserver_1080p_01.webp 609w"],
              "sizes": "(max-width: 609px) 100vw, 609px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/989c3ef9e0f5af4f95b012531ef40309/81c8e/Alarmserver_1080p_01.png 230w", "/en/static/989c3ef9e0f5af4f95b012531ef40309/08a84/Alarmserver_1080p_01.png 460w", "/en/static/989c3ef9e0f5af4f95b012531ef40309/d0d8c/Alarmserver_1080p_01.png 609w"],
              "sizes": "(max-width: 609px) 100vw, 609px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/989c3ef9e0f5af4f95b012531ef40309/d0d8c/Alarmserver_1080p_01.png",
              "alt": "INSTAR IP Camera Alarm Server Function",
              "title": "INSTAR IP Camera Alarm Server Function",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`HD Camera Series`}</strong>{`: Open the Alarm / Alarmserver menu of your `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Alarm/Alarm_Server/"
      }}>{`1080p Series`}</a>{` or `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/720p_Series/Alarm/Alarmserver/"
      }}>{`720p Series`}</a>{` and configure the HTTP GET command, that is send by your camera, in case of an alarm event - e.g. `}<em parentName="p"><a parentName="em" {...{
          "href": "http://192.168.1.178:666%252Falarm_action?set_light=on&send_sms=1"
        }}>{`http://192.168.1.178:666%252Falarm_action?set_light=on&send_sms=1`}</a></em>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3bfdf9c53bddae3e6cb3a79279419811/0a47e/Alarmserver_00.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "16.521739130434785%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAIAAAAcOLh5AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAg0lEQVQI123L0QrCIBQA0P1/1rOgLrr3Q3Q95HD0YORlusA1MNdfBD1EQef9NEZrY4zR5th1zjkiCm8UQp7n+7KUUmqtj1+11pxz01s7DIM99d77lFKMMaZIRJezn6bbGMdwDev6/J/3bYuIgPCBiFLKHdsKITjnbMOElABw+AIASqkXp7KLB9jLlyoAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3bfdf9c53bddae3e6cb3a79279419811/e4706/Alarmserver_00.avif 230w", "/en/static/3bfdf9c53bddae3e6cb3a79279419811/d1af7/Alarmserver_00.avif 460w", "/en/static/3bfdf9c53bddae3e6cb3a79279419811/56a33/Alarmserver_00.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3bfdf9c53bddae3e6cb3a79279419811/a0b58/Alarmserver_00.webp 230w", "/en/static/3bfdf9c53bddae3e6cb3a79279419811/bc10c/Alarmserver_00.webp 460w", "/en/static/3bfdf9c53bddae3e6cb3a79279419811/e88ff/Alarmserver_00.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3bfdf9c53bddae3e6cb3a79279419811/81c8e/Alarmserver_00.png 230w", "/en/static/3bfdf9c53bddae3e6cb3a79279419811/08a84/Alarmserver_00.png 460w", "/en/static/3bfdf9c53bddae3e6cb3a79279419811/0a47e/Alarmserver_00.png 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3bfdf9c53bddae3e6cb3a79279419811/0a47e/Alarmserver_00.png",
              "alt": "INSTAR IP Camera Alarm Server Function",
              "title": "INSTAR IP Camera Alarm Server Function",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`VGA Camera Series`}</strong>{`: Open the web user interface of your camera and navigate to Alarm / Alarm Server. Type in your alarm server address and activate the function. Please add the http prefix when entering the servers IP address - e.g. `}<a parentName="p" {...{
        "href": "http://192.168.1.66"
      }}>{`http://192.168.1.66`}</a>{`.`}</p>
    <h2 {...{
      "id": "application",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#application",
        "aria-label": "application permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Application`}</h2>
    <p>{`The Alarm Server function can be used with alarm systems and home automations like the OPEN SOURCE PROJECT FHEM. Unfortunately, because those home automation systems insist on using SPACEs in their URLs, programing your camera becomes a little bit more complicated. As I am writing this article we are already testing a new version of the web user interface for our VGA camera´s that will make the work-around below superfluous. The following will show you how you are able to determine, if your camera is handling the command correctly and how to prepare your command to bypass the problem in case the camera is not saving it as expected.`}</p>
    <p>{`Home automation system´s like FHEM use SPACEs in their URLs that have to be encoded as %20.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f345611d3e1c6ca88a399bb3c0033953/0a47e/Alarmserver_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "16.521739130434785%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAuUlEQVQI113My2rCQACF4bx+S1yKlmSRmSy6qItSumiLGrzQ5gqGdiCJgYyQmfgWvygo2MW3+jnH2W42RFFEtFiyWq1JkgSlFH9K8bvbUX1+cYgT+jSjz3Lsd4zRGmMtxpgbay1d1+EURU6apsQ/MWVZ0jQNdVVRt3tUUdD6Avsx5/D2Tj17pX9+Ydi32OPxcnI1DANaaxwpJf8JIQjDEM/zGLkjvOkTk/EY9+ER3/cRUhIEwZ3z5txOhhPD0flN4EAAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f345611d3e1c6ca88a399bb3c0033953/e4706/Alarmserver_01.avif 230w", "/en/static/f345611d3e1c6ca88a399bb3c0033953/d1af7/Alarmserver_01.avif 460w", "/en/static/f345611d3e1c6ca88a399bb3c0033953/56a33/Alarmserver_01.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f345611d3e1c6ca88a399bb3c0033953/a0b58/Alarmserver_01.webp 230w", "/en/static/f345611d3e1c6ca88a399bb3c0033953/bc10c/Alarmserver_01.webp 460w", "/en/static/f345611d3e1c6ca88a399bb3c0033953/e88ff/Alarmserver_01.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f345611d3e1c6ca88a399bb3c0033953/81c8e/Alarmserver_01.png 230w", "/en/static/f345611d3e1c6ca88a399bb3c0033953/08a84/Alarmserver_01.png 460w", "/en/static/f345611d3e1c6ca88a399bb3c0033953/0a47e/Alarmserver_01.png 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f345611d3e1c6ca88a399bb3c0033953/0a47e/Alarmserver_01.png",
              "alt": "INSTAR IP Camera Alarm Server Function",
              "title": "INSTAR IP Camera Alarm Server Function",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You have to use a so called URL encoding to employ SPACEs in your web address. An example command for the home automation system FHEM is set alarm on to trigger an alarm event. This will encode to set%20alarm%20on for the camera. The receiving FHEM server will then decode the %20 back to a SPACE. If, for example, your (FHEM) alarm server has the local IP address 192.168.1.66 inside your network, the Server Address you have to type in your cameras webUI to have it activate the alarm event will be.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`http://192.168.1.66/fhem?cmd=set%20alarm%20on`}</code></pre></div>
    <p>{`(The maximal length of the URL is 128 characters)`}</p>
    <p>{`You can check if the command was saved correctly by calling your cameras configuration with the CGI command `}<em parentName="p"><a parentName="em" {...{
          "href": "http://192.168.1.230/get%5C_params.cgi"
        }}>{`http://192.168.1.230/get\\_params.cgi`}</a></em>{` (where 192.168.1.230 has to be changed to the IP address of your IP camera. You will be asked to log in with your camera administrator account, if you are not logged in already. Scroll to the bottom of the parameter list and search for the variable `}<strong parentName="p">{`alarm`}{`_`}{`http`}{`_`}{`url`}</strong>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/a9b26f3407ad0612658cbc698b989883/0a47e/Alarmserver_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "6.521739130434782%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAABCAYAAADeko4lAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAOUlEQVQI143D2wnAIBBFwfTfiqwsQawmK3mwPzf2cGzBgTmeK7iLEeaM6rxn42udrI4y0ZxIQvq3LgirR7cEonYMAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a9b26f3407ad0612658cbc698b989883/e4706/Alarmserver_02.avif 230w", "/en/static/a9b26f3407ad0612658cbc698b989883/d1af7/Alarmserver_02.avif 460w", "/en/static/a9b26f3407ad0612658cbc698b989883/56a33/Alarmserver_02.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/a9b26f3407ad0612658cbc698b989883/a0b58/Alarmserver_02.webp 230w", "/en/static/a9b26f3407ad0612658cbc698b989883/bc10c/Alarmserver_02.webp 460w", "/en/static/a9b26f3407ad0612658cbc698b989883/e88ff/Alarmserver_02.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/a9b26f3407ad0612658cbc698b989883/81c8e/Alarmserver_02.png 230w", "/en/static/a9b26f3407ad0612658cbc698b989883/08a84/Alarmserver_02.png 460w", "/en/static/a9b26f3407ad0612658cbc698b989883/0a47e/Alarmserver_02.png 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/a9b26f3407ad0612658cbc698b989883/0a47e/Alarmserver_02.png",
              "alt": "INSTAR IP Camera Alarm Server Function",
              "title": "INSTAR IP Camera Alarm Server Function",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can check if the server address and FHEM command was saved correctly by calling the cameras configuration page with the CGI command `}<strong parentName="p">{`get`}{`_`}{`params.cgi`}</strong>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/caa263876866b18a254d643c2506092c/0a47e/Alarmserver_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "6.956521739130435%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAABCAYAAADeko4lAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAATklEQVQI1x2KUQqAIBAFu3ofEaS12VreKDqFWNTiDfqRXrgD8zNMI22HyzGSJciy4nFezT5AeNOWOeAcZ8RhQjKkbzVawt0bvPuBylcKfpFSPofYOYCoAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/caa263876866b18a254d643c2506092c/e4706/Alarmserver_03.avif 230w", "/en/static/caa263876866b18a254d643c2506092c/d1af7/Alarmserver_03.avif 460w", "/en/static/caa263876866b18a254d643c2506092c/56a33/Alarmserver_03.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/caa263876866b18a254d643c2506092c/a0b58/Alarmserver_03.webp 230w", "/en/static/caa263876866b18a254d643c2506092c/bc10c/Alarmserver_03.webp 460w", "/en/static/caa263876866b18a254d643c2506092c/e88ff/Alarmserver_03.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/caa263876866b18a254d643c2506092c/81c8e/Alarmserver_03.png 230w", "/en/static/caa263876866b18a254d643c2506092c/08a84/Alarmserver_03.png 460w", "/en/static/caa263876866b18a254d643c2506092c/0a47e/Alarmserver_03.png 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/caa263876866b18a254d643c2506092c/0a47e/Alarmserver_03.png",
              "alt": "INSTAR IP Camera Alarm Server Function",
              "title": "INSTAR IP Camera Alarm Server Function",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The server address has to be saved with the %20 encoding.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/03b6c79ef108f8470b55fa0742768b87/0a47e/Alarmserver_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "37.826086956521735%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABpklEQVQoz33RbW/SUBjG8X5rNdvLmek38KWYEZCYJbo36CLPmAUhGxsP42GUlva0PJTBadcCgf4NNGqMxpP8kivnxXWfO0d5Frvj6KzJUbzJi1id47M2z9/WOU50OPlo8fJizMknm5OLCafpBa+/uJyml7+8+iwP9vd7Slefkfl2S7nWolBpkLu65Wv5hnSucsh75WqL7FWdUu2ecq3L94ZGra1TbWrUe4LrjsFd36bRt1EAbMPANg3GlomhaTgTG/noADvC7QYII+E28p+jyKWk2+li6iNGms5QHWKagiAI8FwP/8lHSkng+1FtCLtd+LcwokxnDq1OB2FZCGEjLBtV1zFMwdL18PyApfeEH6x+vvOfOAwLUazBAPHQZzx8YKJrGL19VpkLAzkWrOYO/nzKzlvCOoC1H9n4v/M6OJQdVlbfpBilLum9u8RIZRAfMtjnBazzPGo8yzCRQ09mUeN59GSRYbKIliyixovo7wsMEmVELE24lFFhsyS4ztvUSzr3FZt2dcrgRvKou2wXLivHZTN3Wc325J8cSTCTrGcLwm30WT8A8d1EtGBIk08AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/03b6c79ef108f8470b55fa0742768b87/e4706/Alarmserver_04.avif 230w", "/en/static/03b6c79ef108f8470b55fa0742768b87/d1af7/Alarmserver_04.avif 460w", "/en/static/03b6c79ef108f8470b55fa0742768b87/56a33/Alarmserver_04.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/03b6c79ef108f8470b55fa0742768b87/a0b58/Alarmserver_04.webp 230w", "/en/static/03b6c79ef108f8470b55fa0742768b87/bc10c/Alarmserver_04.webp 460w", "/en/static/03b6c79ef108f8470b55fa0742768b87/e88ff/Alarmserver_04.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/03b6c79ef108f8470b55fa0742768b87/81c8e/Alarmserver_04.png 230w", "/en/static/03b6c79ef108f8470b55fa0742768b87/08a84/Alarmserver_04.png 460w", "/en/static/03b6c79ef108f8470b55fa0742768b87/0a47e/Alarmserver_04.png 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/03b6c79ef108f8470b55fa0742768b87/0a47e/Alarmserver_04.png",
              "alt": "INSTAR IP Camera Alarm Server Function",
              "title": "INSTAR IP Camera Alarm Server Function",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Every time an alarm is triggered inside the camera the HTTP request will be send to your home automation server, triggering your custom command.`}</p>
    <h2 {...{
      "id": "troubleshooting",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#troubleshooting",
        "aria-label": "troubleshooting permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Troubleshooting`}</h2>
    <p><strong parentName="p">{`What to do if your camera does not save the command with the %20 URL encoding?`}</strong></p>
    <p>{`As mentioned above, you might already be using a camera where the web user interface takes care of the encoding for you and the command is saved correctly - please skip this paragraph if this is the case. In the following we will employ a little trick to make the encoding for us instead of against us.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/d7fe5701daa659b17ae7efb01a705d01/0a47e/Alarmserver_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "6.956521739130435%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAABCAYAAADeko4lAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAATklEQVQI1x3KOQqAMAAAQf8Pgo0k5hafIL4nHomKrbVZIcV006S2I4qB2Es2ocjGV7cbuVwgaccTJnapWYWq7zSeQ1mysrzzAt9HAUop/NfcQMRnSkynAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d7fe5701daa659b17ae7efb01a705d01/e4706/Alarmserver_06.avif 230w", "/en/static/d7fe5701daa659b17ae7efb01a705d01/d1af7/Alarmserver_06.avif 460w", "/en/static/d7fe5701daa659b17ae7efb01a705d01/56a33/Alarmserver_06.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/d7fe5701daa659b17ae7efb01a705d01/a0b58/Alarmserver_06.webp 230w", "/en/static/d7fe5701daa659b17ae7efb01a705d01/bc10c/Alarmserver_06.webp 460w", "/en/static/d7fe5701daa659b17ae7efb01a705d01/e88ff/Alarmserver_06.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/d7fe5701daa659b17ae7efb01a705d01/81c8e/Alarmserver_06.png 230w", "/en/static/d7fe5701daa659b17ae7efb01a705d01/08a84/Alarmserver_06.png 460w", "/en/static/d7fe5701daa659b17ae7efb01a705d01/0a47e/Alarmserver_06.png 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/d7fe5701daa659b17ae7efb01a705d01/0a47e/Alarmserver_06.png",
              "alt": "INSTAR IP Camera Alarm Server Function",
              "title": "INSTAR IP Camera Alarm Server Function",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`What to do if the CGI command get`}{`_`}{`params.cgi shows you that the camera saved the command with SPACEs instead of the %20 encoding?`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/8a863a40cb36f6d56bc16b3d4743485a/0a47e/Alarmserver_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "38.69565217391304%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABjElEQVQoz4WP3W/ScBSG+48bdTfLsg//B1hUSLzQaSIM8HqGoSCltHSlH0D7a0spK0LR0sfANpV54UmevOec5H1zjnT8RuHpS5mDUo8nhWsOyirPXsk8L/U5fOtw+M7h6GLM0fsJJ5cxJ9UZp9XZTs8uY44rEWe1OafVmBe1W6RovqTVVukoOs2vPa47/R1XrS7NtkJXuaEta3R6Ol+6KsrA4cYWDCwXcxygWxOGowDD9rDdKRJAPJ3iTsYEvmBk2wRCEEch2c8fwAbyBzL+V9I6TdFUDXNoYg1NTMPAtCwWi++sVity2CfP2dyT/6UPSFEYIssyhmEwUDXGtoPaVxloKsJzCcOARbIgyzZ7xt/wKFAIH094BGHAdBYhfJ/tbn47J4ymJElCksxZpsud+XFtQ/ZedosXjIofsIoVrEIFv1zDL3/a4ZUaOOcN3NdbrWMX69jndcxCg0nhI5lh3oVuNn8CR7KPdiXof3YYNMfoLYH1LSLQY9ZBzErEpP4d2zkN7nt/Rp6u/7nyF/yHSv3jYBVeAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8a863a40cb36f6d56bc16b3d4743485a/e4706/Alarmserver_07.avif 230w", "/en/static/8a863a40cb36f6d56bc16b3d4743485a/d1af7/Alarmserver_07.avif 460w", "/en/static/8a863a40cb36f6d56bc16b3d4743485a/56a33/Alarmserver_07.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/8a863a40cb36f6d56bc16b3d4743485a/a0b58/Alarmserver_07.webp 230w", "/en/static/8a863a40cb36f6d56bc16b3d4743485a/bc10c/Alarmserver_07.webp 460w", "/en/static/8a863a40cb36f6d56bc16b3d4743485a/e88ff/Alarmserver_07.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/8a863a40cb36f6d56bc16b3d4743485a/81c8e/Alarmserver_07.png 230w", "/en/static/8a863a40cb36f6d56bc16b3d4743485a/08a84/Alarmserver_07.png 460w", "/en/static/8a863a40cb36f6d56bc16b3d4743485a/0a47e/Alarmserver_07.png 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/8a863a40cb36f6d56bc16b3d4743485a/0a47e/Alarmserver_07.png",
              "alt": "INSTAR IP Camera Alarm Server Function",
              "title": "INSTAR IP Camera Alarm Server Function",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Your camera will now send out the HTTP request every time the alarm is triggered. But the request will be cut before the first SPACE - making it basically useless.`}</p>
    <p>{`We can fix this dilemma by having the automatic URL encoding generate the correct URL for us. This can be done by encoding the % sign to %25. The server address will become:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`http://192.168.1.66/fhem?cmd=set%2520alarm%2520on`}</code></pre></div>
    <p>{`The automatic encoding will generate the correct address from it once you submit this address:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`http://192.168.1.66/fhem?cmd=set%20alarm%20on`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "600px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4253109dc8c03d1e8919d82939439ee2/0a47e/Alarmserver_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "16.521739130434785%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAwklEQVQI11XMQU/CQBiE4f57iCEcOFgtBbqREIOBSluJB45CwQtKaIhA3EbYb/dfvAYIJh6eTGYO470/J7wlKdN4RJ5mLCcTdvmc7SznazpjN19w/Fzh1gW2KHDLD+RwwIhgjPkjImit8bZ3AZveI2vV5ftpgM3GmCRD0hd+4hFlf4hLXznGCfv+EHnoYbVGnDufXFlrKcsST3U6qFYL1W6fMzoJL73p+9SrNwS3Pn6jQa1SpXkfoJQiiqJ/TlsYhvwCIofBU1+obkIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4253109dc8c03d1e8919d82939439ee2/e4706/Alarmserver_05.avif 230w", "/en/static/4253109dc8c03d1e8919d82939439ee2/d1af7/Alarmserver_05.avif 460w", "/en/static/4253109dc8c03d1e8919d82939439ee2/56a33/Alarmserver_05.avif 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4253109dc8c03d1e8919d82939439ee2/a0b58/Alarmserver_05.webp 230w", "/en/static/4253109dc8c03d1e8919d82939439ee2/bc10c/Alarmserver_05.webp 460w", "/en/static/4253109dc8c03d1e8919d82939439ee2/e88ff/Alarmserver_05.webp 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4253109dc8c03d1e8919d82939439ee2/81c8e/Alarmserver_05.png 230w", "/en/static/4253109dc8c03d1e8919d82939439ee2/08a84/Alarmserver_05.png 460w", "/en/static/4253109dc8c03d1e8919d82939439ee2/0a47e/Alarmserver_05.png 600w"],
              "sizes": "(max-width: 600px) 100vw, 600px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4253109dc8c03d1e8919d82939439ee2/0a47e/Alarmserver_05.png",
              "alt": "INSTAR IP Camera Alarm Server Function",
              "title": "INSTAR IP Camera Alarm Server Function",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`To prevent this from happening, encode the SPACE with %2520 instead of %20.`}</p>
    <p>{`Please make sure not to press the Submit button a second time - as this will again get rid of the %20, substituting it with a SPACE. You can check the result by calling the get_params.cgi command.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      